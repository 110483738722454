<template>
  <v-container>
    <v-row>
      <v-col v-for="(card, c_i) in cards" :key="`card_${c_i}`" cols="12" xl="4" lg="6" md="6" sm="12">
        <v-card :color="$commonProcessor.styleMapping(card.activityType).backgroundColor" height="100%" class="d-flex flex-column">
          <v-row style="flex: 1;  alignContent: center;" class="pa-8">
            <div style="flex: 0.7">
              <h2 class="font-weight-medium white--text">
                {{ card.name }}
              </h2>

              <v-chip v-if="showStatusMessage(card)"
                :color="$commonProcessor.colorLuminance($commonProcessor.styleMapping(card.activityType).backgroundColor, -0.15)" 
                text-color="white"
                class="mt-2"
              >
                <v-icon small class="pr-2" color="white">mdi-checkbox-marked-circle-outline</v-icon>
                {{ showStatusMessage(card) }}
              </v-chip>
            </div>
            <div style="flex: 0.3;">
              <v-col class="pa-0 ma-0" align="center">
                <v-icon color="white" x-large>
                  {{ $commonProcessor.styleMapping(card.activityType).icon }}
                </v-icon>
              </v-col>
              <v-col class="pa-0 ma-0 white--text text-capitalize" align="center">
                {{ card.activityType }}
              </v-col>
            </div>
          </v-row>

          <v-spacer />

          <v-card-actions>
            <div v-if="card.config && card.config.steps && card.config.steps.length > 0">
              <v-btn text color="white" v-if="card.completed == true" @click="() => startUserProfile(card)">
                {{ $t('take_again') }}
              </v-btn>

              <v-btn text color="white" v-else @click="() => startUserProfile(card)">
                {{ $t('start') }}
              </v-btn>
            </div>

            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text color="white" style="opacity: 0.7" v-on="on" v-bind="attrs">
                    {{ $t('app_only') }}
                  </v-btn>
                </template>
                <span>{{ $t('available_on_mobile_app_only')}}</span>
              </v-tooltip>
            </div>

            <v-btn text color="white" @click="() => $router.push({ name: (category == 'screener') ? 'ShowUserProfileData' : 'ShowPassiveDataData', params: { name: card.name, dashboardViewDataPath: card.dashboardViewDataPath, userProfilingId: card._id } })" v-if="card.dashboardViewDataPath">
              {{ $t('view_data') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DataService } from '../services/DataService'

const dataService = new DataService()

export default {
  data() {
    return {
      cards: [],
    }
  },
  props: {
    category: String
  },
  created() {
    this.$cookies.set('platform', 'web')
  },
  mounted() {
    this.fetchUserProfiling()
  },
  methods: {
    showStatusMessage(row) {
      let completed = row.completed != undefined && row.completed == true
      let required = row.config && row.config.required != undefined && row.config.required == true

      if (completed) 
        return this.$t('completed')
      else if (!completed && required)
        return this.$t('required')
      else
        return false
    },
    fetchUserProfiling() {
      this.$setLoader()
      dataService.getUserProfile(this.$store.state.token, { 
        userId: this.$store.state.userProfile._id,
        renderType: "webList",
        limit: 100,
        category: this.category
      }).then(data => {
        this.cards.push(...data.profilings)
        this.$clearLoader()
      })
    },
    startUserProfile(card) {
      this.$router.push({ name: 'ShowPassiveData', params: { card } })
    }
  }
}
</script>